import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/appmain/AppLayout'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    {
      path: '*',
      redirect: { name: 'dashboard' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('../components/auth/login/Login.vue'),
        },
        {
          name: 'signup',
          path: 'signup',
          component: () => import('../components/auth/login/Signup.vue'),
        },
        {
          name: 'set-new-password',
          path: 'set-new-password',
          component: () => import('../components/auth/login/SetNewPassword.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          component: () => import('../components/auth/login/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [
        {
          name: 'not-found-simple',
          path: 'not-found-simple',
          component: () => import('../components/pages/404-pages/VaPageNotFoundSimple.vue'),
        },
      ],
    },
    {
      path: '/dummy',
      children: [
        {
          name: 'logout',
          path: 'logout',
          redirect: { name: 'login' },
        },
        {
          name: 'login-is-ok',
          path: 'login-is-ok',
        },
        {
          name: 'login-is-off',
          path: 'login-is-off',
        },
      ],
    },
    {
      name: 'AppMain',
      path: '/appmain',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          name: 'baubilder',
          path: 'baubilder',
          component: () => import('../components/dashboard/BauBilder.vue'),
        },
        {
          name: 'gewerkeuserchoices',
          path: 'gewerkeuserchoices',
          component: () => import('../components/anmelden/GewerkeAuswahl.vue'),
        },
        {
          name: 'anmelden',
          path: 'anmelden',
          component: () => import('../components/anmelden/Anmelden.vue'),
        },
        {
          name: 'tagesarbeit',
          path: 'tagesarbeit',
          component: () => import('../components/anmelden/Tagesarbeit.vue'),
        },
        {
          name: 'kueche',
          path: 'kueche',
          component: () => import('../components/anmelden/Kueche.vue'),
        },
        {
          name: 'uebersichtbau',
          path: 'uebersichtbau',
          component: () => import('../components/anmelden/UebersichtBau.vue'),
        },
        {
          name: 'uebersichtkueche',
          path: 'uebersichtkueche',
          component: () => import('../components/anmelden/UebersichtKueche.vue'),
        },
        {
          name: 'adminpages',
          path: 'adminpages',
          component: EmptyParentComponent,
          children: [
            {
              name: 'newsedit',
              path: 'newsedit',
              component: () => import('../components/dashboard/NewsEdit.vue'),
            },
            {
              name: 'bauevents',
              path: 'bauevents',
              component: () => import('../components/anmelden/BauEvents.vue'),
            },
            {
              name: 'gewerkechoicegroup',
              path: 'gewerkechoicegroup',
              component: () => import('../components/anmelden/GewerkePlanung.vue'),
            },
          ],
        },
      ],
    },
  ],
})
