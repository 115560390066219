
const MsgTypes = {
  noAuth: 'noAuth',
  loginOK: 'loginOK',
  logoutOK: 'logoutOK',
  checkAuthToken: 'checkAuthToken',
  doLogin: 'doLogin',
  doLogout: 'doLogout',
  getEncryptKey: 'getEncryptKey',

  registerUser: 'registerUser',
  changeUserPassword: 'changeUserPassword',
  requestPwdReset: 'requestPwdReset',

  getUserForms: 'getUserForms',

  getTestMsgArr: 'getTestMsgArr',
}

export default MsgTypes
