<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="va-icon-vuestic"
    width="205"
    height="31"
    viewBox="0 0 205 31"
  >
    <defs>
      <linearGradient :id="'ORIGINAL'" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#4AE387"/>
        <stop offset="100%" stop-color="#C8EA13"/>
      </linearGradient>
      <linearGradient :id="'CORPORATE'" x1="0%" y1="50%" y2="50%">
        <stop offset="0%" stop-color="#74BBFF"/>
        <stop offset="100%" stop-color="#6E85E8"/>
      </linearGradient>
    </defs>
    <g
      fill="none"
      fill-rule="evenodd"
      transform="scale(2)"
    >
      <text
        xml:space="preserve"
        text-anchor="start"
        font-family="sans-serif"
        font-weight="bold"
        font-size="16"
        id="svg_1" y="12" x="0"
        opacity="undefined"
        stroke-width="0"
        stroke="#000"
        :fill="`url(#${themeGradientId})`"
      >Höningen</text>
      <text
        xml:space="preserve"
        text-anchor="start"
        font-family="sans-serif"
        font-weight="bold"
        font-size="8"
        id="svg_1" y="7" x="87"
        opacity="undefined"
        stroke-width="0"
        stroke="#000"
        :fill="textColor"
      >1.5</text>
    </g>
  </svg>
</template>
<script>

export default {
  name: 'VaIconVuestic',
  inject: ['contextConfig'],
  computed: {
    themeGradientId () {
      return this.contextConfig.invertedColor ? 'CORPORATE' : 'ORIGINAL'
    },
    textColor () {
      return this.contextConfig.invertedColor ? '#6E85E8' : '#E4FF32'
    },
  },
}
</script>

<style lang="scss">
.va-icon-vuestic {
  .st0 {
    fill: #4ae387;
  }
}
</style>
