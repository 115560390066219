
'use strict'

import SrvConn from '../srvconn/index'
import MsgTypes from '../srvconn/msgTypes'

export default {
  intIsLoggedIn: false,
  intUserName: '',
  intLoginName: '',
  loginToken: null,
  currEncryptKey: '',

  sleep: function (ms) {
    return new Promise((resolve, reject) => setTimeout(resolve, ms))
  },

  initAuth: function () {
    return new Promise((resolve, reject) => {
      SrvConn.chechSocketConnected()
        .then(response => {
          resolve()
        })
        .catch(err => {
          console.log('ERROR initAuth: ' + err)
          reject(err)
        })
    })
  },

  getEncryptedPassword: function (authData) {
    return new Promise((resolve, reject) => {
      SrvConn.sendMessage(MsgTypes.getEncryptKey)
        .then(response => {
          if (response.msgType === MsgTypes.getEncryptKey) {
            var currEncryptKey = new Uint8Array(response.userData.encryptKey.match(/.{1,2}/g).map(byte => parseInt(byte, 16)))
            var aesjs = require('aes-js')
            var textBytes = aesjs.utils.utf8.toBytes(authData.password)
            // eslint-disable-next-line
            var aesCtr = new aesjs.ModeOfOperation.ctr(currEncryptKey, new aesjs.Counter(5))
            var encryptedBytes = aesCtr.encrypt(textBytes)
            var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)
            resolve(encryptedHex)
          } else {
            reject(new Error('getEncryptKey failed'))
          }
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  checkServerLogin: function (authData) {
    return new Promise((resolve, reject) => {
      // console.log('info', 'login as: ' + authData.userid)

      this.getEncryptedPassword(authData)
        .then(encryptedHex => {
          SrvConn.sendMessage(MsgTypes.doLogin, { username: authData.userid, password: encryptedHex })
            .then(response => {
              if (response.msgType === MsgTypes.loginOK) {
                console.log('successful login')
                resolve(response)
              } else {
                reject(new Error('login failed'))
              }
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  login: function (authData) {
    return new Promise((resolve, reject) => {
      this.checkServerLogin(authData)
        .then(loginRes => {
          this.intIsLoggedIn = true
          this.intUserName = loginRes.userData.userName
          this.intLoginName = loginRes.userData.userLogin
          this.loginToken = loginRes.userData.authToken
          resolve()
          /*
          if ((authData.userid === 'test') && (authData.password === 'test')) {
          } else {
            reject(new Error('login failed'))
          }
          */
        })
        .catch(loginErr => {
          this.intIsLoggedIn = false
          this.loginToken = null
          console.log('ERROR2: ' + loginErr)
          reject(loginErr)
        })
    })
  },

  logout: function () {
    this.intIsLoggedIn = false
    this.loginToken = null
    SrvConn.sendMessage(MsgTypes.doLogout)
      .then(response => {
        if (response.msgType === MsgTypes.logoutOK) {
          console.log('successful logout')
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  logoutQuiet: function () {
    this.intIsLoggedIn = false
    this.loginToken = null
  },

  isLoggedIn: function () {
    return this.intIsLoggedIn
  },

  loggedInUser: function () {
    return this.intUserName
  },

  loginMail: function () {
    return this.intLoginMail
  },

  registerUser: function (authData) {
    return new Promise((resolve, reject) => {
      // console.log('info', 'registerUser as: ' + authData.username)

      this.getEncryptedPassword(authData)
        .then(encryptedHex => {
          authData.password = encryptedHex
          authData.regURL = authData.regURL.replace('signup', 'set-new-password') + '?userEmail=' + authData.email + '&pwdResetToken='
          SrvConn.sendMessage(MsgTypes.registerUser, authData)
            .then(response => {
              if (response.msgType === MsgTypes.registerUser) {
                console.log('successful registration')
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  requestPwdReset: function (authData) {
    return new Promise((resolve, reject) => {
      authData.regURL = authData.regURL.replace('recover-password', 'set-new-password') + '?userEmail=' + authData.email + '&pwdResetToken='
      SrvConn.sendMessage(MsgTypes.requestPwdReset, authData)
        .then(response => {
          if (response.msgType === MsgTypes.requestPwdReset) {
            console.log('successful requestPwdReset')
            resolve(response)
          } else {
            reject(response)
          }
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  changePasswd: function (authData) {
    return new Promise((resolve, reject) => {
      this.getEncryptedPassword(authData)
        .then(encryptedHex => {
          authData.password = encryptedHex
          SrvConn.sendMessage(MsgTypes.changeUserPassword, authData)
            .then(response => {
              if (response.msgType === MsgTypes.changeUserPassword) {
                console.log('password changed')
                resolve(response)
              } else {
                reject(response)
              }
            })
            .catch(err => {
              console.log(err)
              reject(err)
            })
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },
}
