
'use strict'

import userAuth from '../userAuth/index'

var SrvConn = {
  websockBaseUrl: '',
  wsConn: null,
  connCount: 0,
  dataCallback: null,
  lastMsgType: null,
  lastResolve: null,
  lastReject: null,
  connectResolve: null,
  connectReject: null,

  initConn: function (iBaseUrl) {
    SrvConn.websockBaseUrl = iBaseUrl
  },

  chechSocketConnected: function () {
    return new Promise((resolve, reject) => {
      SrvConn.connectResolve = resolve
      SrvConn.connectReject = reject

      if ((SrvConn.wsConn === null) || (SrvConn.wsConn.readyState === WebSocket.CLOSED)) {
        SrvConn.connCount++
        SrvConn.wsConn = new WebSocket(SrvConn.websockBaseUrl + '?connCount=' + SrvConn.connCount)
        console.log('(re)connect websockets')

        SrvConn.wsConn.onopen = SrvConn.wsOnOpen
        SrvConn.wsConn.onmessage = SrvConn.wsOnMessage
        SrvConn.wsConn.onerror = SrvConn.wsOnError
      } else {
        // console.log('already connected')
        resolve({ status: 'alive' })
      }
    })
  },

  wsOnOpen: function () {
    if (SrvConn.connectResolve !== null) {
      console.log('websocket connected')
      userAuth.logoutQuiet()
      SrvConn.connectResolve({ status: 'connected' })
    }
  },

  wsOnMessage: function (iEvent) {
    var data = JSON.parse(iEvent.data)

    // console.log('wsOnMessage', data)

    if (SrvConn.lastResolve !== null) {
      SrvConn.lastResolve(data)
    }
    // else {
    //   console.log('event', data)
    // }
  },

  wsOnError: function (iError) {
    console.log('error', iError)
  },

  sendMessage: function (iMsgType, iData) {
    return new Promise((resolve, reject) => {
      SrvConn.lastMsgType = iMsgType
      SrvConn.lastResolve = resolve
      SrvConn.lastReject = reject

      // console.log('info', 'sendMessage: ' + iMsgType + ', data: ' + iData)

      // Message: { msgType: ENUM, userData = {} }

      var data = {
        msgType: iMsgType,
      }
      if (iData !== null) {
        data.userData = iData
      }

      SrvConn.chechSocketConnected()
        .then(result => {
          SrvConn.wsConn.send(JSON.stringify(data))
        })
        .catch(err => {
          console.log('ERROR sendMessage: ' + err)
        })
    })
  },
}

export default SrvConn

/*

var authToken = ''
var dataCallback = null

var lastWSMessage = null

function saveAuthToken( i_token )
{
  authToken = i_token
}

function repeatLastMessage()
{
  return

  //if( chechSocketConnected() )
  //{
  //  var toSend = JSON.stringify( lastWSMessage )
  //  wsConn.send( toSend )
  //  console.log( 'sent', toSend )
  //}
}

function startWSConnection( iDataCallback )
{
  dataCallback = iDataCallback
  chechSocketConnected()
  setInterval( chechSocketConnected, 10000 )
}

*/
