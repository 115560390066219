
'use strict'

import SrvConn from '../srvconn/index'
import MsgTypes from '../srvconn/msgTypes'

/*

{
  name: 'anmelden',
  path: 'anmelden',
  component: () => import(),
  default: true,
},

*/
var availableUserForms = {
  newsedit: {
    name: 'newsedit',
    displayName: 'menu.newsedit',
    meta: {
      iconClass: 'entypo-megaphone',
    },
  },
  bauevents: {
    name: 'bauevents',
    displayName: 'menu.bauevents',
    meta: {
      iconClass: 'entypo-calendar',
    },
  },
  uebersichtbau: {
    name: 'uebersichtbau',
    displayName: 'menu.uebersichtbau',
    meta: {
      iconClass: 'entypo-back-in-time',
    },
  },
  uebersichtkueche: {
    name: 'uebersichtkueche',
    displayName: 'menu.uebersichtkueche',
    meta: {
      iconClass: 'maki-warehouse',
    },
  },
  gewerkechoicegroup: {
    name: 'gewerkechoicegroup',
    displayName: 'menu.gewerkechoicegroup',
    meta: {
      iconClass: 'entypo-sound',
    },
  },
  adminpages: {
    name: 'adminpages',
    displayName: 'menu.adminpages',
    disabled: true,
    meta: {
      iconClass: 'entypo-flow-cascade',
    },
    children: [
    ],
  },
}

// this.addSingleUserForm(entry)

export default {
  navRoutes: null,
  authenticatedForms: ['dashboard', 'baubilder'],
  allowedUserForms: null,

  isFormAuthenticated: function (iName) {
    return this.authenticatedForms.includes(iName)
  },

  addAuthenticatedForm: function (iName) {
    if (!this.authenticatedForms.includes(iName)) {
      this.authenticatedForms.push(iName)
    }
  },

  getUserForms: function () {
    return new Promise((resolve, reject) => {
      SrvConn.sendMessage(MsgTypes.getUserForms)
        .then(response => {
          if (response.msgType === MsgTypes.getUserForms) {
            this.allowedUserForms = response.userData.userForms
            resolve()
          } else {
            reject(new Error('getUserForms failed'))
          }
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  },

  removeUserForms: function () {
    for (var k in availableUserForms) {
      this.removeSingleUserForm(k)
    }
  },

  addAllowedForms: function (iNavRoutes) {
    this.navRoutes = iNavRoutes
    if (this.allowedUserForms) {
      this.allowedUserForms.forEach((entry) => {
        this.addSingleUserForm(entry)
      })
    }
  },

  addSingleUserForm: function (iName) {
    var isNewItem = true
    var entryPath = iName.split('/')
    var entryName = entryPath[0]
    var baseName = ''
    var basePushItem = this.navRoutes
    if (entryPath.length === 2) {
      baseName = entryPath[0]
      entryName = entryPath[1]
      this.addSingleUserForm(baseName)
    }
    if (baseName === '') {
      this.navRoutes.forEach((entry) => {
        if (entry.name === iName) {
          isNewItem = false
        }
      })
    } else {
      this.navRoutes.forEach((entry) => {
        if (entry.name === baseName) {
          basePushItem = entry.children
          basePushItem.forEach((entry) => {
            if (entry.name === entryName) {
              isNewItem = false
            }
          })
        }
      })
    }

    this.addAuthenticatedForm(entryName)

    if (isNewItem) {
      basePushItem.push(availableUserForms[entryName])
    }
  },

  removeSingleUserForm: function (iName) {
    var foundEntry = null
    this.navRoutes.forEach((entry) => {
      if (entry.name === iName) {
        foundEntry = entry
      }
    })
    if (foundEntry) {
      this.navRoutes.pop(foundEntry)
    }
  },
}
