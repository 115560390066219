export const navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      },
    },
    {
      name: 'baubilder',
      displayName: 'menu.baubilder',
      meta: {
        iconClass: 'entypo-camera',
      },
    },
    {
      name: 'gewerkeuserchoices',
      displayName: 'menu.gewerkeuserchoices',
      meta: {
        iconClass: 'entypo-flashlight',
      },
    },
    {
      name: 'anmelden',
      displayName: 'menu.anmelden',
      meta: {
        iconClass: 'entypo-suitcase',
      },
    },
    {
      name: 'tagesarbeit',
      displayName: 'menu.tagesarbeit',
      meta: {
        iconClass: 'entypo-tools',
      },
    },
    {
      name: 'kueche',
      displayName: 'menu.kueche',
      meta: {
        iconClass: 'entypo-basket',
      },
    },
  ],
}
