// Polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import { ColorThemePlugin } from '../services/vuestic-ui'
import store from '../store/index'
import router from '../router/index'
import { VuesticPlugin } from '../services/vuestic-ui/components'
import '../i18n/index'
import VueClipboard from 'vue-clipboard2'

import '../metrics'
import '../registerServiceWorker'

import srvconn from '../services/srvconn/index'
import userauth from '../services/userAuth/index'
import userforms from '../services/userForms/index'

import { consoleBuildInfo } from 'vue-cli-plugin-build-info/plugin'

consoleBuildInfo()

Vue.use(VuesticPlugin)
Vue.use(VueClipboard)

Vue.use(ColorThemePlugin, {
  // override colors here.
})

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.name === 'logout') {
    userauth.logout()
    userforms.removeUserForms()
  }
  if (to.name === 'login-is-off') {
    userforms.removeUserForms()
  }

  var nextIsOK = false

  if ((to.name === 'login') ||
      (to.name === 'signup') ||
      (to.name === 'set-new-password') ||
      (to.name === 'recover-password')) {
    nextIsOK = true
  } else if (userauth.isLoggedIn()) {
    if (userforms.isFormAuthenticated(to.name)) {
      nextIsOK = true
    }
  }

  store.commit('setLoading', true)
  if (nextIsOK) {
    next()
  } else {
    next({ name: 'login' })
  }
})

router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})

Vue.prototype.$srvconn = srvconn
Vue.prototype.$userauth = userauth
Vue.prototype.$userforms = userforms

if (process.env.NODE_ENV === 'production') {
  srvconn.initConn('wss://bau.mbgft.de/wLlGV5y2jlKrZ6b')
  userauth.initAuth()
} else {
  srvconn.initConn('ws://192.168.77.36:8721/wLlGV5y2jlKrZ6b')
  userauth.initAuth()
}
