import Vue from 'vue'

// add translations directly to the application
Vue.i18n.add('de', require('./de.json'))

// set the start locale to use
Vue.i18n.set('de')

// set fallback for non-translated strings
Vue.i18n.fallback('de')
